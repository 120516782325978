<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.item.titulos.visualizar_reserva')"
      sem-botao-ok
      sem-filtro
      sem-registros
    />
    <div>
      <div>
        <div>
          <v-chip
            class="mr-1"
            small
          >
            {{ $t('modulos.item.visualizar_reserva_chip.codigo') }}: {{ badges.codigo }}
          </v-chip>
          <v-chip
            class="mr-1"
            small
          >
            {{ $t('modulos.item.visualizar_reserva_chip.fabricante') }}: {{ badges.fabricante }}
          </v-chip>
          <v-chip
            class="mr-1"
            small
          >
            {{ $t('modulos.item.visualizar_reserva_chip.descricao') }}: {{ descricao }}
          </v-chip>
        </div>
      </div>
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="false"
      sem-paginacao
      :filtros-com-colunas="false"
      ajustar-altura-linha
    >
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <template>
            <div class="d-flex justify-center">
              <icone-padrao
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
              />
            </div>
          </template>
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ItemService from '@common/services/cadastros/ItemService';
import { enumOperacaoSituacaoIcons } from '@enums/icons';
export default {
  name: 'VisualizarReserva',
  components: {
    CabecalhoPagina,
  },
  props: ['id'],

  data() {
    return {
      badges: {
        codigo: '',
        fabricante: '',
      },

      descricao: '',
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'ordemServico.codigo',
            text: this.$t(
              'modulos.item.visualizar_reserva_tabela.ordem_servico'
            ),
            sortable: false,
          },
          {
            value: 'instrumento.codigo',
            text: this.$t('modulos.item.visualizar_reserva_tabela.instrumento'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.item.visualizar_reserva_tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'setorAtual.nome',
            text: this.$t('modulos.item.visualizar_reserva_tabela.setor'),
            sortable: false,
          },
          {
            value: 'quantidadePecas',
            text: this.$t('modulos.item.visualizar_reserva_tabela.qtd_pecas'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      // Your component's data properties here
    };
  },
  computed: {
    // Your component's computed properties here
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.item.titulos.visualizar_reserva')
    );
    if (this.id) {
      this.buscarReserva(this.id);
    }
  },
  methods: {
    buscarReserva: function (id) {
      ItemService.buscarReservaPecas(id).then((res) => {
        this.badges.codigo = res.data.codigo;
        this.badges.fabricante = res.data.fabricante.nome || 'Sem Fabricante';
        this.descricao = res.data.descricao;
        this.tabela.dados = res.data.instrumentosPrecisamPecas;
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
    voltar: function () {
      this.$router.push({ name: this.$route.query?.retorno || 'item' });
    },
  },
};
</script>

<style scoped>
/* Your component's styles here */
</style>
